<template>
    <v-container>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="signUp">

            <v-text-field label="Display Name" v-model="displayName" :rules="[v => !!v || 'Display Name is required']"
                required @keyup.enter="signUp" aria-labelledby="Display Name"></v-text-field>

            <v-text-field label="Email" v-model="email" :rules="emailRules" required @keyup.enter="signUp"
                aria-labelledby="Email"></v-text-field>

            <v-text-field label="Password" v-model="password" :rules="passwordRules" type="password" required
                @keyup.enter="signUp" aria-labelledby="Password"></v-text-field>

            <v-text-field label="Confirm Password" v-model="confirmPassword"
                :rules="[v => v === password || 'Passwords do not match']" type="password" required @keyup.enter="signUp"
                aria-labelledby="Confirm Password">
            </v-text-field>


            <v-btn @click="signUp" :disabled="!valid || loading" color="primary">
                <template v-if="loading">
                    <v-progress-circular indeterminate></v-progress-circular>
                </template>
                <template v-else>
                    Sign Up
                </template>
            </v-btn>
        </v-form>
        <br />
        <v-snackbar v-model="showSnackbar" color="error" :timeout="3000">
            {{ errorMessage }}
            <v-btn text @click="showSnackbar = false">Close</v-btn>
        </v-snackbar>
        <p>Or</p>
        <v-btn @click="signInWithGoogle" color="white">
            <v-img src="@/assets/google-logo.png" aspect-ratio="1" class="mr-2" contain height="24" min-width="24"></v-img>
            <template v-if="loadingGoogle">
                <v-progress-circular indeterminate></v-progress-circular>
            </template>
            <template v-else>
                Sign Up with Google
            </template>
        </v-btn>
        <br />
        <br />
        <p>
            By signing up, you agree to our <router-link to="/terms">Terms of Service</router-link> and <router-link
                to="/privacy">Privacy Policy</router-link>.
        </p>
        <p>Already have an account? <router-link to="/login">Login here</router-link></p>

    </v-container>
</template>

  
<script>
import axios from 'axios';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile, GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { mapGetters } from "vuex";
import { getAnalytics, logEvent } from 'firebase/analytics';
const analytics = getAnalytics();

export default {
    data: () => ({
        valid: true,
        displayName: "",
        confirmPassword: "",
        email: "",
        password: "",
        loading: false,
        loadingGoogle: false,
        selectedPlan: "Free",
        showSnackbar: false,
        errorMessage: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        passwordRules: [
            (v) => !!v || "Password is required",
            (v) => v.length >= 6 || "Password must be at least 6 characters long",
            (v) => /[A-Za-z]/.test(v) || "Password must contain letters",
            (v) => /[0-9]/.test(v) || "Password must contain numbers",
            (v) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) || "Password must contain special characters",
        ]
    }),
    computed: {
        ...mapGetters([
            'user'
        ])
    },
    methods: {
        async signUp() {
            if (this.$refs.form.validate()) {
                this.loading = true;  // Start the loading spinner
                const auth = getAuth();
                try {
                    const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
                    const user = userCredential.user;
                    // Update the user's profile with the display name
                    await updateProfile(user, {
                        displayName: this.displayName
                    })
                    // Step 2: Send a verification email
                    await sendEmailVerification(user);

                    // Save additional profile information in Firestore
                    const db = getFirestore();
                    await setDoc(doc(db, 'users', user.uid), {
                        displayName: this.displayName,
                        createdAt: new Date(),
                        last_usage_date: new Date(),
                        usage_count: 0,
                        emailVerified: false,
                        // Add any additional user data you want to store
                    });

                    await this.$router.push('/verify-email');
                    logEvent(analytics, 'signup_success');
                    console.log('signup_success event');

                } catch (error) {
                    console.error("Error signing up or creating user document: ", error);
                    if (error.code === 'auth/email-already-in-use') {
                        this.errorMessage = 'This email is already registered. Please try to login.';
                    } else if (error.code === 'auth/invalid-email') {
                        this.errorMessage = 'The email address is badly formatted. Please enter a valid email.';
                    } else if (error.code === 'auth/weak-password') {
                        this.errorMessage = 'The password is too weak. Please enter a stronger password.';
                    } else {
                        this.errorMessage = 'An unknown error occurred. Please try again.';
                    }
                    this.showSnackbar = true; // Show the Snackbar
                } finally {
                    this.loading = false;  // End the loading spinner irrespective of success or failure
                }
            }
        },
        async signInWithGoogle() {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            const db = getFirestore();
            this.loadingGoogle = true; // Start the loadingGoogle spinner or some other user feedback

            try {
                // Step 1: Sign in the user with Google
                const result = await signInWithPopup(auth, provider);

                // Check if the user is new or existing
                const additionalUserInfo = getAdditionalUserInfo(result);
                const isNewUser = additionalUserInfo ? additionalUserInfo.isNewUser : undefined;

                if (isNewUser === undefined) {
                    console.error("additionalUserInfo is undefined");
                    return;
                }

                // Step 2: Get the user object from the result
                const user = result.user;

                const db = getFirestore();
                const userDoc = doc(db, 'users', user.uid);
                const userDocSnapshot = await getDoc(userDoc);

                // If the user is new or the document doesn't exist, set the document
                if (isNewUser || !userDocSnapshot.exists()) {
                    await setDoc(userDoc, {
                        displayName: user.displayName,
                        createdAt: new Date(),
                        last_usage_date: new Date(),
                        usage_count: 0,
                        emailVerified: true
                    });

                    // Create Stripe customer for new user
                    const token = this.user.accessToken;
                    const headers = {
                        Authorization: `Bearer ${token}`
                    };
                    await axios.post(`${process.env.VUE_APP_API_ADDRESS}/create_stripe_customer`, {
                        user_id: user.uid,
                        email: user.email,
                        subscription_level: this.selectedPlan,
                    }, {
                        headers: headers
                    });

                    // Log signup event only for new users
                    logEvent(analytics, 'signup_success');
                    console.log('signup_success event');
                }

                // Step 5: Navigate to the homepage
                await this.$router.push('/');

            } catch (error) {
                console.error("Error signing in with Google or creating user document: ", error);
            } finally {
                this.loadingGoogle = false;  // End the loadingGoogle spinner or provide other user feedback
            }
        }

    }
};
</script>
